import API from "../../../api";
import TextInputComponent from "../../../component/input/text-input/text-input";
import ContestModel from "../../../data/contest/model";
import LinkData from "../../../data/link/link";
import {openPage} from "../../../utils/common";
import {RenderPosition, render} from "../../../utils/render";

export const renderContestsList = (container, json) => {
  const contests = ContestModel.parseContests(json);
  const nowDate = Math.floor(Date.now() / 1000);
  const inputComponent = new TextInputComponent({
    label: `Мероприятие`,
    value: ``,
    isReadOnly: true,
    dropDownItems: contests.map((contest) => {
      const DeadlineExpire = contest.affirmationDeadline ? contest.affirmationDeadline < nowDate : false;
      const text = contest.title
      console.log(contest.affirmationDeadline)
      const comment = DeadlineExpire ? `<span style="color: red;"> - Прием заявок завершен</span>` : '';
      return {
        id: contest.contestId,
        text,
        comment,
        };
    }),

    onDropDownItemCheck: (contestId) => {
  const selectedItem = contests.find((contest) => contest.contestId === contestId);
  if (selectedItem && (!selectedItem.affirmationDeadline || selectedItem.affirmationDeadline > nowDate) ) {
      const link = `/registration?id=${contestId}`;
      new LinkData({api: new API()}).getShortLink(link, (shortLink) => {
        openPage(shortLink, `_self`);
      });
    }
   },
  });

  render(container, inputComponent.getElement(), RenderPosition.BEFOREEND);
};

