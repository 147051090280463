import LangController from "../lang";
import LangoSettingNewController from "../lango-setting_new";


export default class GlobalSettingLangoController extends LangoSettingNewController {
  constructor({api}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);
    this._nameSetting = `globalSetting`;
    this._setting = LangController.getPropertyLango(this._nameSetting);
  }

  get verificationMail() {
    return this.get(`verificationMail`) || {mail: ``, mailId: ``};
  }

  get languages() {
    return this.get(`languages`) || [{lang: `ru`, isShow: `true`}];
  }

  set verificationMail(data) {
    this.set(`verificationMail`, data);
  }

  set languages(data) {
    this.set(`languages`, data);
  }
}

