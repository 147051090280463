import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import OrganizationModel from "./model";
import OrganizationParsing from "./parsing";
import OrganizationRequest from "./request";

export default class OrganizationData {
  constructor({api, isShowError = true, organizationId = ``}) {
    this._api = api;
    this._isShowError = isShowError;
    this._organizationId = organizationId;
  }

  getAllData({parameters}) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.getAll,
        parameter: {
          parameters,
        },
        checkRequiredParameter: {},
        requiredResponseParameters: [],
        parseResponse: OrganizationModel.parseOrganizations,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по организациям.`),
      }).send();
    });
  }

  getOneData({organizationId, parameters}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.getOne,
        parameter: {
          organizationId,
          parameters,
        },
        checkRequiredParameter: {organizationId},
        requiredResponseParameters: [],
        parseResponse: (data) => OrganizationModel.parseOrganization(organizationId, data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по организации.`),
      }).send();
    });
  }

  createOrganization(title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.title,
        parameter: {title, organizationId: NTR.ZERO},
        checkRequiredParameter: {title},
        requiredResponseParameters: [NTR.ORGANIZATION_ID],
        parseResponse: OrganizationParsing.organizationId,
        onResolveHandler: (organizationId) => resolve(organizationId),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось создать организацию.`),
      }).send();
    });
  }

  setTitle(organizationId, title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.title,
        parameter: {title, organizationId},
        checkRequiredParameter: {title, organizationId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить название организации.`),
      }).send();
    });
  }

  setCity(organizationId, city, cityId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.city,
        parameter: {organizationId, city, cityId},
        checkRequiredParameter: {organizationId, city, cityId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить город организации.`),
      }).send();
    });
  }

  setCityPrint(organizationId, isCityPrint) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: OrganizationRequest.cityPrint,
        parameter: {organizationId, isCityPrint},
        checkRequiredParameter: {organizationId, isCityPrint},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить город организации.`),
      }).send();
    });
  }

  setMailServer({mailServer, login, password}, onResolveHandler) {
    new RequestController({
      api: this._api,
      getRequest: OrganizationRequest.setMailServer,
      parameter: {organizationId: this._organizationId, mailServer, login, password},
      checkRequiredParameter: {organizationId: this._organizationId, mailServer},
      requiredResponseParameters: [],
      onResolveHandler: () => onResolveHandler(),
      onRejectHandler: () => {},
      onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить исходящую почту.`),
    }).send();
  }
}
