import {NTR} from "../../name-to-request";
import {getCodeCheck} from "../../utils/common";
import {getDataRequest} from "../../utils/request";
import {ALL_PERFORMANCE_PARAMETERS} from "./parameter";

export default class PerformanceRequest {
  static getAll({order, filter, parameters = ALL_PERFORMANCE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PERFORMANCE_ID]: NTR.ZERO,
        [NTR.SIZE]: NTR.ALL,
        [NTR.ORDER_BY]: order,
        [NTR.FILTER]: filter,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static getOne({performanceId, filter, parameters = ALL_PERFORMANCE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.SIZE]: NTR.ZERO,
        [NTR.FILTER]: filter,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static getSeveral({count = NTR.ONE, order, filter, parameters = ALL_PERFORMANCE_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PERFORMANCE_ID]: NTR.ZERO,
        [NTR.SIZE]: count,
        [NTR.ORDER_BY]: order,
        [NTR.FILTER]: filter,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static setRefereeRating({performanceId, refereeId, rating}) {
    return {
      [NTR.SET_PERFORMANCE_RATING]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.REFEREE_ID]: refereeId,
        [NTR.CRITERIA]: rating,
      }
    };
  }

  static setRefereeRatingAll({performanceId, rating}) {
    return {
      [NTR.SET_PERFORMANCE_RATING]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.RATING]: rating
      }
    };
  }

  static setCriteriaRating({performanceId, rating}) {
    return {
      [NTR.SET_PERFORMANCE_RATING]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.CRITERIA]: rating,
      }
    };
  }

  static delete({performanceId}) {
    return {
      [NTR.SET_PERFORMANCE_DELETE]: {
        [NTR.PERFORMANCE_ID]: performanceId,
      }
    };
  }

  static setArchive({performanceId, isArchive}) {
    return {
      [NTR.SET_PERFORMANCE_ARCHIVE]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.ARCHIVE]: getCodeCheck(isArchive),
      }
    };
  }

  static setStatusMoney({performanceId, status}) {
    return {
      [NTR.SET_PERFORMANCE_MONEY]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.MONEY_STATUS]: status,
      }
    };
  }

  static setToPayMoney({performanceId, toPay, status}) {
    return {
      [NTR.SET_PERFORMANCE_MONEY]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.AMOUNT_TO_PAY]: toPay,
        [NTR.MONEY_STATUS]: status,
      }
    };
  }

  static setPaidMoney({performanceId, paid, status}) {
    return {
      [NTR.SET_PERFORMANCE_MONEY]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.AMOUNT_PAID]: paid,
        [NTR.MONEY_STATUS]: status,
      }
    };
  }

  // static sendEmail({performanceId, mailId, template, userMessage}) {
  //   return {
  //     [NTR.SEND_EMAIL]: {
  //       [NTR.PERFORMANCE_ID]: performanceId,
  //       [NTR.MAIL_ID]: mailId,
  //       [NTR.TEMPLATE_NAME]: template,
  //       [NTR.USER_MESSAGE]: userMessage,
  //     }
  //   };
  // }

  static contest({performanceId, contestId}) {
    return {
      [NTR.SET_PERFORMANCE_CONTEST]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PERFORMANCE_CONTEST]: contestId,
      }
    };
  }

  static title({performanceId, title}) {
    return {
      [NTR.SET_PERFORMANCE_TITLE]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PERFORMANCE_TITLE]: title,
      }
    };
  }

  static city({performanceId, city, googleId}) {
    return {
      [NTR.SET_PERFORMANCE_CITY]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PERFORMANCE_CITY]: city,
        [NTR.GOOGLE_CITY]: googleId,
      }
    };
  }

  static createGroup({performanceId}) {
    return {
      [NTR.SET_PERFORMANCE_GROUP]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
      }
    };
  }

  static changeGroup({performanceId, groupId}) {
    return {
      [NTR.SET_PERFORMANCE_GROUP]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.GROUP_ID]: groupId,
      }
    };
  }

  static nomination({performanceId, nominationId}) {
    return {
      [NTR.SET_PERFORMANCE_NOMINATION]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.NOMINATION_ID]: nominationId,
      }
    };
  }

  static ageCategory({performanceId, ageCategoryId}) {
    return {
      [NTR.SET_PERFORMANCE_AGE_CATEGORY]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.AGE_CATEGORY_ID]: ageCategoryId,
      }
    };
  }

  static qualification({performanceId, qualificationId}) {
    return {
      [NTR.SET_PERFORMANCE_QUALIFICATION]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.QUALIFICATION_ID]: qualificationId,
      }
    };
  }

  static team({performanceId, teamId}) {
    return {
      [NTR.SET_PERFORMANCE_TEAM]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.TEAM_ID]: teamId,
      }
    };
  }

  static teamForce({performanceId, teamId}) {
    return {
      [NTR.SET_PERFORMANCE_TEAM]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.TEAM_ID]: teamId,
        [NTR.FORCE]: NTR.ONE,
      }
    };
  }

  static deleteTeam({performanceId, teamId}) {
    return {
      [NTR.SET_PERFORMANCE_TEAM]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.TEAM_ID]: teamId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static organization({performanceId, organizationId}) {
    return {
      [NTR.SET_PERFORMANCE_ORGANIZATION]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.ORGANIZATION_ID]: organizationId,
      }
    };
  }

  static organizationForce({performanceId, organizationId}) {
    return {
      [NTR.SET_PERFORMANCE_ORGANIZATION]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.FORCE]: NTR.ONE,
      }
    };
  }

  static deleteOrganization({performanceId, organizationId}) {
    return {
      [NTR.SET_PERFORMANCE_ORGANIZATION]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static setDirector({performanceId, peopleId, positionText}) {
    return {
      [NTR.SET_PERFORMANCE_DIRECTOR]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PEOPLE_ID]: peopleId,
        [NTR.DIRECTOR_TITLE]: positionText,
      }
    };
  }

  static deleteDirector({directorId}) {
    return {
      [NTR.SET_PERFORMANCE_DIRECTOR]: {
        [NTR.DIRECTOR_ID]: directorId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static setPlayer({performanceId, peopleId}) {
    return {
      [NTR.SET_PERFORMANCE_PLAYER]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PEOPLE_ID]: peopleId,
      }
    };
  }

  static deletePlayer({performanceId, playerId}) {
    return {
      [NTR.SET_PERFORMANCE_PLAYER]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PLAYER_ID]: playerId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static playerCount({performanceId, count}) {
    return {
      [NTR.SET_PERFORMANCE_PLAYER_COUNT]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PLAYER_COUNT]: count,
      }
    };
  }

  static isPlayerCountName({performanceId, isCheck}) {
    return {
      [NTR.SET_PERFORMANCE_PLAYER_COUNT_NAME_SHOW]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PLAYERS_COUNT_NAME_SHOW]: getCodeCheck(isCheck),
      }
    };
  }

  static setContactPeople({performanceId, peopleId}) {
    return {
      [NTR.SET_PERFORMANCE_CONTACT]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.PEOPLE_ID]: peopleId,
      }
    };
  }

  static setPhonogram({performanceId, phonogramId}) {
    return {
      [NTR.SET_PERFORMANCE_PHONOGRAM]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.PHONOGRAM_ID]: phonogramId,
      }
    };
  }

  static setPerformanceDuration({performanceId, duration}) {
    return {
      [NTR.SET_PERFORMANCE_DURATION]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.DURATION]: duration
      }
    };
  }

  static setIsRequiredPhonogram({performanceId, isCheck}) {
    return {
      [NTR.SET_PERFORMANCE_PHONOGRAM_STATUS]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.PHONOGRAM_STATUS]: isCheck ? `1000` : `null`,
      }
    };
  }

  static setUserField({fieldId, performanceId, value = ``}) {
    return {
      [NTR.SET_USER_DATA]: {
        [NTR.FIELD_ID]: fieldId,
        [NTR.SOURCE_ID]: String(performanceId),
        [NTR.FIELD_DATA]: value,
      }
    };
  }

  static addLink({performanceId, link}) {
    return {
      [NTR.SET_PERFORMANCE_LINK]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.LINK]: link,
      }
    };
  }

  static deleteLinks({performanceId}) {
    return {
      [NTR.SET_PERFORMANCE_LINK]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static deleteFiles({performanceId}) {
    return {
      [NTR.FILE_DELETE]: {
        [NTR.PERFORMANCE_ID]: performanceId,
      }
    };
  }

  static changeLink({linkId, link}) {
    return {
      [NTR.SET_PERFORMANCE_LINK]: {
        [NTR.LINK_ID]: linkId,
        [NTR.LINK]: link,
      }
    };
  }

  static deleteLink({linkId}) {
    return {
      [NTR.SET_PERFORMANCE_LINK]: {
        [NTR.LINK_ID]: linkId,
        [NTR.DELETE]: NTR.ONE,
      }
    };
  }

  static deleteFile({fileId}) {
    return {
      [NTR.FILE_DELETE]: {
        [NTR.FILE_ID]: fileId,
      }
    };
  }

  static addFile({performanceId, fileId}) {
    return {
      [NTR.SET_PERFORMANCE_FILE]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
        [NTR.FILE_ID]: fileId,
      }
    };
  }

  static copy({performances}) {
    return {
      [NTR.SET_PERFORMANCE_COPY]: performances
    };
  }

  static setNote({performanceId, authorType, note}) {
    return {
      [NTR.SET_NOTE]: {
        [NTR.PERFORMANCE_ID]: performanceId,
        [NTR.AUTHOR_TYPE]: authorType,
        [NTR.NOTE]: note,
      }
    };
  }

  static setRegistration({performanceId}) {
    return {
      [NTR.SET_PERFORMANCE_REGISTER]: {
        [NTR.PERFORMANCE_ID]: String(performanceId),
      }
    };
  }

  static setTurn({ids, target}) {
    return {
      [NTR.SET_PERFORMANCE_TURN]: {
        [NTR.TURN]: ids,
        [NTR.ID_TARGET]: target,
      }
    };
  }
}

