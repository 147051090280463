import {AUTHORIZATION_FORM_FIELD, ComponentType, CONTEST_FORM_FIELD, TemplateRF} from "../../const";
import LangController from "../lang";
import LangSettingNewController from "../lang-setting_new";

const TEMPLATE_DEFAULT = TemplateRF.BASE;
const COUNT_PERFORMANCE_DEFAULT = 1;

const SETTING_TABS_DEFAULT = {
  mapTabs: [
    {
      tabId: `tab1`,
      tabTitle: ``,
      components: []
    },
  ],
};

const START_TABS_DEFAULT = [
  {
    tabId: CONTEST_FORM_FIELD.name,
    tabTitle: `Мероприятие`,
    components: [
      {
        type: ComponentType.FIELD,
        name: CONTEST_FORM_FIELD.name,
        setting: CONTEST_FORM_FIELD.setting
      }
    ],
  }, {
    tabId: AUTHORIZATION_FORM_FIELD.name,
    tabTitle: `Представьтесь`,
    components: [
      {
        type: ComponentType.FIELD,
        name: AUTHORIZATION_FORM_FIELD.name,
        setting: AUTHORIZATION_FORM_FIELD.setting,
      }
    ],
  }
];

export default class RegistationPageLangNewController extends LangSettingNewController {
  constructor({api}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);
    this._nameSettingOld = `settingRegistrationForm`;
    this._settingOld = LangController.getPropertyLang(this._nameSettingOld);

    // переход на новый отдельный ланг
    this._nameSetting = `settingRegistrationPage`;
    this._nameLang = `lang_registration`;
    this._setting = LangController.getSettingNewLang(window[this._nameSetting]);
  }

  save() {
    this.saveNewLang();
  }

  getAll() {
    return {
      template: this.template,
      countPerformance: this.countPerformance,
      isGroupPerformances: this.isGroupPerformances,
      startTabs: this.startTabs,
      settingTabsTemplate: this.settingTabsTemplate,
      finishTabs: this.finishTabs,
      peopleCardSetting: this.peopleCardSetting,
      colorMap: this.colorMap,
    };
  }

  setAll(data) {
    this.template = data.template || TEMPLATE_DEFAULT;
    this.countPerformance = data.countPerformance || COUNT_PERFORMANCE_DEFAULT;
    this.isGroupPerformances = data.isGroupPerformances;
    this.startTabs = data.startTabs || START_TABS_DEFAULT;
    this.settingTabsTemplate = data.settingTabsTemplate || SETTING_TABS_DEFAULT;
    this.finishTabs = data.finishTabs || [];
    this.peopleCardSetting = data.peopleCardSetting || {};
    this.colorMap = data.colorMap || {};
  }

  reset() {
    this.template = TEMPLATE_DEFAULT;
    this.countPerformance = COUNT_PERFORMANCE_DEFAULT;
    this.isGroupPerformances = false;
    this.startTabs = START_TABS_DEFAULT;
    this.settingTabsTemplate = SETTING_TABS_DEFAULT;
    this.finishTabs = [];
    this.peopleCardSetting = {};
    this.colorMap = {};
  }

  saveAs(contestId) {
    LangController.saveAsNew(this._setting, contestId);
  }

  get template() {
    return this.getData(`template`) || TEMPLATE_DEFAULT;
  }

  get countPerformance() {
    return this.getData(`countPerformance`) || COUNT_PERFORMANCE_DEFAULT;
  }

  get isGroupPerformances() {
    const isGroupPerformances = this.getData(`isGroupPerformances`);
    return isGroupPerformances !== null ? isGroupPerformances : false;
  }

  get startTabs() {
    return this.getData(`startTabs`) || START_TABS_DEFAULT;
  }

  get settingTabsTemplate() {
    return this.getData(`settingTabsTemplate`) || SETTING_TABS_DEFAULT;
  }

  get mapTabs() {
    return this.settingTabsTemplate.mapTabs;
  }

  get finishTabs() {
    return this.getData(`finishTabs`) || [];
  }

  get peopleCardSetting() {
    return this.getData(`peopleCardSetting`) || {};
  }

  get colorMap() {
    return this.getData(`colorMap`) || {};
  }

  set template(data) {
    this.set(`template`, data);
  }

  set countPerformance(data) {
    this.set(`countPerformance`, data);
  }

  set isGroupPerformances(data) {
    this.set(`isGroupPerformances`, data);
  }

  set startTabs(data) {
    this.set(`startTabs`, data);
  }

  set settingTabsTemplate(data) {
    this.set(`settingTabsTemplate`, data);
  }

  set mapTabs(data) {
    const settingTabsTemplate = this.getData(`settingTabsTemplate`) || {};
    settingTabsTemplate.mapTabs = data;
    this.settingTabsTemplate = settingTabsTemplate;
  }

  set finishTabs(data) {
    this.set(`finishTabs`, data);
  }

  set peopleCardSetting(data) {
    this.set(`peopleCardSetting`, data);
  }

  set colorMap(data) {
    this.set(`colorMap`, data);
  }
}
