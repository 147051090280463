import {ComponentType, Language, LanguageToEngText} from "../../const";
import {DefaultTitleRegistrationField} from "../../const/registration-field";

export const getTitleRegistrationField = (languageData, nameField, defaultValue = ``, typeField = `field`) => {
  if (!languageData) {
    return defaultValue;
  }

  const language = languageData.language;

  if (typeField === ComponentType.FIELD) {
    if (language === LanguageToEngText[Language.RU]) {
      return languageData[nameField] || defaultValue || `${DefaultTitleRegistrationField[nameField]}`;
    }

    return languageData[nameField] || `${DefaultTitleRegistrationField[nameField]} (${language})`;
  }

  if (typeField === ComponentType.USER_FIELD) {
    if (language === LanguageToEngText[Language.RU]) {
      return languageData.getUserFieldTitle(nameField) || `${defaultValue}`;
    }

    return languageData.getUserFieldTitle(nameField) || `${defaultValue} (${language})`;
  }

  if (typeField === ComponentType.TEXT) {
    if (language === LanguageToEngText[Language.RU]) {
      return languageData.getData(`${ComponentType.TEXT}_${nameField}`) || defaultValue;
    }

    return languageData.getData(`${ComponentType.TEXT}_${nameField}`) || `${defaultValue} (${language})`;
  }

  return defaultValue;
};


export const getTitleRegistrationFieldWithComponentId = (languageData, nameField, componentId = `000000`, defaultValue, typeField = `field`) => {
  if (!languageData) {
    return defaultValue;
  }

  const language = languageData.language;
  const name = `${nameField}_${componentId}`;
  if (typeField === ComponentType.FIELD) {
    if (componentId === `000000`) {
      if (language === LanguageToEngText[Language.RU]) {
        return languageData.getData(name) || languageData.getData(nameField) || defaultValue || `${DefaultTitleRegistrationField[nameField]}`;
      }

      return languageData.getData(name) || languageData.getData(nameField) || `${DefaultTitleRegistrationField[nameField]} (${language})`;
    } else {
      if (language === LanguageToEngText[Language.RU]) {
        return languageData.getData(name) || defaultValue || `${DefaultTitleRegistrationField[nameField]}`;
      }
  
      return languageData.getData(name) || `${DefaultTitleRegistrationField[nameField]} (${language})`;
    }
    
  }

  // if (typeField === ComponentType.USER_FIELD) {
  //   return languageData.getUserFieldTitle(nameField) || `${defaultValue} (${language})`;
  // }

  // if (typeField === ComponentType.TEXT) {
  //   if (language === LanguageToEngText[Language.RU]) {
  //     return languageData.getData(`${ComponentType.TEXT}_${nameField}`) || defaultValue;
  //   }

  //   return languageData.getData(`${ComponentType.TEXT}_${nameField}`) || `${defaultValue} (${language})`;
  // }

  return defaultValue;
};
