import LangController from "../lang";
import LangSettingNewController from "../lang-setting_new";


export default class GlobalSettingLangController extends LangSettingNewController {
  constructor({api}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);
    this._nameSettingOld = `globalSetting`;
    this._settingOld = LangController.getPropertyLang(this._nameSettingOld);
    this._settingLangoOld = LangController.getPropertyLango(this._nameSettingOld);

    // переход на новый отдельный ланг
    this._nameSetting = `settingCommon`;
    this._nameSettingO = `settingCommonO`;
    this._nameLang = `lang_common`;
    this._nameLangO = `lango_common`;
    this._setting = LangController.getSettingNewLang(window.settingCommon);
    this._settingLango = LangController.getSettingNewLang(window.settingCommonO);
  }

  save() {
    this.saveNewLang();
  }

  get headerColor() {
    return this.getData(`HEADER_COLOR`) || `#ffffff`;
  }

  get logoColor() {
    return this.getData(`LOGO_COLOR`) || `#ffffff`;
    // return this.get(`LOGO_COLOR`) || this.getLango(`LOGO_COLOR`) || `#ffffff`;
  }

  get textHeaderColor() {
    return this.getData(`TEXT_HEADER_COLOR`) || `#51ddd4`;
    // return this.get(`TEXT_HEADER_COLOR`) || this.getLango(`TEXT_HEADER_COLOR`) || `#51ddd4`;
  }

  get mainColor() {
    return this.getData(`MAIN_COLOR`) || `#51ddd4`;
    // return this.get(`MAIN_COLOR`) || this.getLango(`MAIN_COLOR`) || `#51ddd4`;
  }

  get logo() {
    return this.getData(`LOGO`) || `sienge/img/logo_primetime.svg`;
    // return this.get(`LOGO`) || this.getLango(`LOGO`) || `sienge/img/logo_primetime.svg`;
  }

  get documentIds() {
    return this.get(`DOCUMENT_IDS`) || [];
  }

  get isShowBasicDocument() {
    const isShowBasicDocument = this.get(`isShowBasicDocument`);
    return isShowBasicDocument !== null ? isShowBasicDocument : false;
  }

  get verificationMail() {
    return this.get(`verificationMail`) || {mail: ``, mailId: ``};
  }

  get isShowRegistrationNavItem() {
    const isShowRegistrationNavItem = this.get(`isShowRegistrationNavItem`);
    return isShowRegistrationNavItem !== null ? isShowRegistrationNavItem : true;
  }

  get noWatermarkDocumentIds() {
    return this.get(`noWatermarkDocumentIds`) || [];
  }

  set headerColor(data) {
    this.set(`HEADER_COLOR`, data);
  }

  set logoColor(data) {
    this.set(`LOGO_COLOR`, data);
  }

  set textHeaderColor(data) {
    this.set(`TEXT_HEADER_COLOR`, data);
  }

  set mainColor(data) {
    this.set(`MAIN_COLOR`, data);
  }

  set logo(data) {
    this.set(`LOGO`, data);
  }

  set documentIds(data) {
    this.set(`DOCUMENT_IDS`, data);
  }

  set isShowBasicDocument(data) {
    this.set(`isShowBasicDocument`, data);
  }

  set verificationMail(data) {
    this.set(`verificationMail`, data);
  }

  set isShowRegistrationNavItem(data) {
    this.set(`isShowRegistrationNavItem`, data);
  }

  set noWatermarkDocumentIds(data) {
    this.set(`noWatermarkDocumentIds`, data);
  }
}

